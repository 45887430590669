
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
import SearchDate from "@/components/request/SearchDate.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    SearchDate,
    IdentifyUser: () => import("@/components/request/IdentifyUser.vue"),
    SendRequestDate: () => import("@/components/request/SendRequestDate.vue"),
    EndDateRequest: () => import("@/components/request/EndDateRequest.vue")
  }
})
export default class NewDate extends Vue {
  private mounted() {
    this.$store.dispatch("setStateToOne");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }
}
