
import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { QCalendar } from "@quasar/quasar-ui-qcalendar";
import {
  parseDate,
  parseTimestamp,
  addToDate,
  isBetweenDates
} from "@quasar/quasar-ui-qcalendar";

interface reservaData {
  title: string | any;
  bgcolor: string | any;
  date: string | any;
  icon: string | any;
  time: any;
  duration: any;
  side: any;
  days: any;
  id: any;
  fecha: any;
}

const CURRENT_DAY = new Date();

function getCurrentDay(day: any, month: any, ano: any) {
  const newDay = new Date(CURRENT_DAY);

  const monthNewDay = newDay.getMonth() + 1;

  if (monthNewDay == month) {
    newDay.setDate(day);
    newDay.setFullYear(ano);
    const tm = parseDate(newDay) as any;

    return tm.date;
  } else {
    newDay.setDate(day);
    const monthNewDay = month - 1;
    newDay.setMonth(monthNewDay);
    newDay.setFullYear(ano);
    const tm = parseDate(newDay) as any;

    return tm.date;
  }
}

const reRGBA = /^\s*rgb(a)?\s*\((\s*(\d+)\s*,\s*?){2}(\d+)\s*,?\s*([01]?\.?\d*?)?\s*\)\s*$/;

function textToRgb(color: any) {
  if (typeof color !== "string") {
    throw new TypeError("Expected a string");
  }

  const m = reRGBA.exec(color);
  if (m) {
    const rgb = {
      r: Math.min(255, parseInt(m[2], 10)),
      g: Math.min(255, parseInt(m[3], 10)),
      b: Math.min(255, parseInt(m[4], 10)),
      a: 0
    };
    if (m[1]) {
      rgb.a = Math.min(1, parseFloat(m[5]));
    }
    return rgb;
  }
  return hexToRgb(color);
}

function hexToRgb(hex: any) {
  if (typeof hex !== "string") {
    throw new TypeError("Expected a string");
  }

  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  } else if (hex.length === 4) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }

  const num = parseInt(hex, 16);

  return hex.length > 6
    ? {
        r: (num >> 24) & 255,
        g: (num >> 16) & 255,
        b: (num >> 8) & 255,
        a: Math.round((num & 255) / 2.55)
      }
    : { r: num >> 16, g: (num >> 8) & 255, b: num & 255 };
}

function luminosity(color: any) {
  if (typeof color !== "string" && (!color || color.r === void 0)) {
    throw new TypeError("Expected a string or a {r, g, b} object as color");
  }

  const rgb = typeof color === "string" ? textToRgb(color) : color,
    r = rgb.r / 255,
    g = rgb.g / 255,
    b = rgb.b / 255,
    R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4),
    G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4),
    B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

@Component({
  components: {
    QCalendar
  }
})
export default class CalendarLista extends Mixins() {
  @Prop() private reservas!: any;

  private todayDate: any = "";
  private yesterday: any = "";
  private today: any = 0;
  private today_year: any = 0;
  private day: any = 1;
  private selectedDate: any = "";
  private selectedDates: Array<any> = [];
  private arrayReservas: Array<reservaData> = [];
  private idUserReserva: any = 0;
  private colorUserReserva: any = "";
  private instruccion: Boolean = true;

  @Watch("selectedDates")
  private changeTime(newValue: any) {}

  private created() {
    if (this.reservas.length > 0) {
      this.getReservas();
    }
  }

  private getReservas() {
    this.arrayReservas = this.reservas;
    /*
    this.reservas.forEach((e: any) => {
      this.arrayReservas.push({
        title: e.nombre_usuario + " " + e.apellido_paterno_usuario,
        bgcolor: "#602baf",
        date: getCurrentDay(
          this.obtenerDia(e.inicio),
          this.obtenerMes(e.inicio),
          this.obtenerAno(e.inicio)
        ),
        icon: "person",
        time: "09:00",
        duration: "",
        side: "",
        days: "",
        id: e.id_usuario,
        fecha: e.inicio
      });
    });
    */
  }

  private obtenerDia(value: any) {
    const newValue = value.split("-");
    return parseInt(newValue[2]);
  }

  private obtenerMes(value: any) {
    const newValue = value.split("-");
    return parseInt(newValue[1]);
  }

  private obtenerAno(value: any) {
    const newValue = value.split("-");
    return parseInt(newValue[0]);
  }

  private enviarData(idUser: any, date: any) {
    this.$emit("obtenerData", {
      id_user: idUser,
      fecha: date
    });
  }

  isCssColor(color: any) {
    return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/);
  }

  badgeClasses(event: any, type: any) {
    const cssColor = this.isCssColor(event.bgcolor);
    const isHeader = type === "header";
    return {
      [`text-white bg-${event.bgcolor}`]: !cssColor,
      "full-width": !isHeader && (!event.side || event.side === "full"),
      "left-side": !isHeader && event.side === "left",
      "right-side": !isHeader && event.side === "right"
    };
  }

  badgeStyles(
    event: any,
    type: any,
    timeStartPos: any,
    timeDurationHeight: any
  ) {
    const s = {
      color: "",
      top: "",
      height: ""
    };
    if (this.isCssColor(event.bgcolor)) {
      (s as any)["background-color"] = event.bgcolor;
      s.color = luminosity(event.bgcolor) > 0.5 ? "black" : "white";
    }
    if (timeStartPos) {
      s.top = timeStartPos(event.time) + "px";
    }
    if (timeDurationHeight) {
      s.height = timeDurationHeight(event.duration) + "px";
    }
    (s as any)["align-items"] = "flex-start";
    return s;
  }

  getEvents(dt: any) {
    const currentDate = parseTimestamp(dt);
    const events = [];
    for (let i = 0; i < this.arrayReservas.length; ++i) {
      let added = false;
      if (this.arrayReservas[i].date === dt) {
        if (this.arrayReservas[i].time) {
          if (events.length > 0) {
            // check for overlapping times
            const startTime = parseTimestamp(
              this.arrayReservas[i].date + " " + this.arrayReservas[i].time
            );
            const endTime = addToDate(startTime as any, {
              minute: this.arrayReservas[i].duration
            });
          }
        }
        if (!added) {
          this.arrayReservas[i].side = void 0;
          events.push(this.arrayReservas[i]);
        }
      } else if (this.arrayReservas[i].days) {
        // check for overlapping dates
        const startDate = parseTimestamp(this.arrayReservas[i].date);
        const endDate = addToDate(startDate as any, {
          day: this.arrayReservas[i].days
        });

        if (isBetweenDates(currentDate as any, startDate as any, endDate)) {
          events.push(this.arrayReservas[i]);
          added = true;
        }
      }
    }
    return events;
  }

  private leftClick(e: any) {
    return e.button === 0;
  }

  onToggleDay({ scope }: any) {
    if (scope !== undefined) {
      this.toggleDate(scope);
    }
  }

  toggleDate(scope: any) {
    const date = scope.timestamp.date;
    if (this.selectedDates.includes(date)) {
      // remove the date
      for (let i = 0; i < this.selectedDates.length; ++i) {
        if (date === this.selectedDates[i]) {
          this.selectedDates.splice(i, 1);
          break;
        }
      }
      if (this.selectedDates.length == 0) {
        this.$emit("limpiarFechas", {
          data: this.selectedDates
        });
      }
    } else {
      // add the date if not outside
      if (scope.outside !== true) {
        this.selectedDates.push(date);
      }
    }
    this.$emit("obtenerFechas", {
      data: this.selectedDates.sort()
    });
  }

  private calendarNext() {
    if (this.today >= 12) {
      this.today = 1;
      this.today_year += 1;
    } else {
      this.today += 1;
    }
    (this.$refs.calendar as any).next();
  }

  private calendarPrev() {
    if (this.today <= 1) {
      this.today = 12;
      this.today_year -= 1;
    } else {
      this.today -= 1;
    }

    (this.$refs.calendar as any).prev();
  }

  private get monthName() {
    switch (this.today) {
      case 1: {
        return "Enero";
      }
      case 2: {
        return "Febrero";
      }
      case 3: {
        return "Marzo";
      }
      case 4: {
        return "Abril";
      }
      case 5: {
        return "Mayo";
      }
      case 6: {
        return "Junio";
      }
      case 7: {
        return "Julio";
      }
      case 8: {
        return "Agosto";
      }
      case 9: {
        return "Septiembre";
      }
      case 10: {
        return "Octubre";
      }
      case 11: {
        return "Noviembre";
      }
      case 12: {
        return "Diciembre";
      }
      default: {
        return "Out of range";
      }
    }
  }

  private generarLetra() {
    const letras = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9"
    ];
    const numero = (Math.random() * 10).toFixed(0);
    return letras[numero as any];
  }

  private colorHEX(user: any) {
    if (this.idUserReserva != user) {
      this.idUserReserva = user;
      for (var j = 0; j < 2; j++) {
        this.colorUserReserva = this.colorUserReserva + this.generarLetra();
      }
      return "#" + this.colorUserReserva;
    } else {
      return "#" + this.colorUserReserva;
    }
  }

  private cerrarDialog() {
    this.instruccion = false;
  }

  private filtrarUser(id: any) {
    this.$emit("obtenerUser", {
      idUser: id
    });
  }

  private beforeMount() {
    let thisDay = new Date();
    let yesterday = new Date(thisDay);

    this.todayDate = [
      thisDay.getFullYear(),
      thisDay.getMonth() + 1,
      thisDay.getDate()
    ].join("-");
    this.today_year = thisDay.getFullYear();
    this.today = thisDay.getMonth() + 1;
    yesterday.setDate(yesterday.getDate() - 1);
    this.yesterday = yesterday.toISOString().split("T")[0];
  }

  private mounted() {}
}
